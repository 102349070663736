import { ApplicationController } from 'stimulus-use'
import interact from 'interactjs'
import { debounce } from 'debounce'

export default class extends ApplicationController {
    // https://www.petercollingridge.co.uk/tutorials/svg/interactive/dragging/
    // https://interactjs.io/docs/draggable/

    static targets = ['rectangle', 'text', 'capacity']

    static values = {
        tableId: Number,
        x: Number,
        y: Number,
        editable: Boolean,
        alreadyReserved: Boolean,
        tableCheckIn: Boolean,
        canReassignTables: Boolean,
    }

    initialize() {
        this.doDrag = false
        this.offset = { x: 0, y: 0 }
        this.textOffset = { x: 9, y: 31, xForThreeDigits: 6 }
        this.capacityOffset = { x: 10, y: -6 }
        this.isFirstDraw = true

        this.persistCoordinates = debounce(this.persistCoordinates, 1000)

        this.redraw()
        this.addListeners()
    }

    addListeners() {
        if (this.editableValue) {
            interact(this.element).draggable({ listeners: { move: this.move.bind(this) } })
        } else if (this.tableCheckInValue == false) {
            if (this.alreadyReservedValue && this.canReassignTablesValue == false) {
                return
            }

            this.rectangleTarget.addEventListener('click', this.pressedOnMapTable.bind(this));
            this.textTarget.addEventListener('click', this.pressedOnMapTable.bind(this));
        }
    }

    pressedOnMapTable() {
        window.dispatchEvent(new CustomEvent('table-selected-on-map', { detail: { tableId: this.tableIdValue } }))
    }

    redraw() {
        this.rectangleTarget.setAttributeNS(null, "x", this.xValue);
        this.rectangleTarget.setAttributeNS(null, "y", this.yValue);

        this.textTarget.setAttributeNS(null, "x", this.xValue + (this.textTarget.textContent.length <= 2 ? this.textOffset.x : this.textOffset.xForThreeDigits));
        this.textTarget.setAttributeNS(null, "y", this.yValue + this.textOffset.y);

        this.capacityTarget.setAttributeNS(null, "x", this.xValue + this.capacityOffset.x);
        this.capacityTarget.setAttributeNS(null, "y", this.yValue + this.capacityOffset.y);
    }

    xValueChanged() {
        this.persistCoordinates()
    }

    yValueChanged() {
        this.persistCoordinates()
    }

    persistCoordinates() {
        if (this.isFirstDraw) {
            this.isFirstDraw = false
            return
        }

        const headers = {
            'X-CSRF-Token': this.csrfToken,
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        const data = {
            x: this.rectangleTarget.x.baseVal.value,
            y: this.rectangleTarget.y.baseVal.value,
            table: {
                x: this.rectangleTarget.x.baseVal.value,
                y: this.rectangleTarget.y.baseVal.value
            }
        }

        fetch(`/tables/${this.tableIdValue}`, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(data)
        })
    }

    move(event) {
        this.xValue += event.dx
        this.yValue += event.dy
        this.redraw()
    }

}
import { ApplicationController, useDebounce } from 'stimulus-use'
import I18n from "i18n-js";

export default class extends ApplicationController {
    static targets = ['maleCount', 'femaleCount', 'neutralCount', 'date', 'place', 'audio']
    static values = {
        existingId: Number,
        forExit: Boolean,
        male: Number,
        female: Number,
        neutral: Number,
        createOrUpdateUrl: String,
        createCounterHistoryUrl: String
    }
    static debounces = ['createOrUpdate']

    connect() {
        useDebounce(this, { wait: 1500 })
    }

    addMale() {
        this.maleValue += 1
        this.feedback()
        this.createOrUpdate()
        this.createCounterHistory("Hombre")
    }

    addFemale() {
        this.femaleValue += 1
        this.feedback()
        this.createOrUpdate()
        this.createCounterHistory("Mujer")
    }

    addNeutral() {
        this.neutralValue += 1
        this.feedback()
        this.createOrUpdate()
        this.createCounterHistory("Salidas")
    }

    maleValueChanged() {
        if (this.hasMaleCountTarget) {
            this.maleCountTarget.innerHTML = this.maleValue
        }
    }

    femaleValueChanged() {
        if (this.hasFemaleCountTarget) {
            this.femaleCountTarget.innerHTML = this.femaleValue
        }
    }

    neutralValueChanged() {
        if (this.hasNeutralCountTarget) {
            this.neutralCountTarget.innerHTML = this.neutralValue
        }
    }

    feedback() {
        const counter_sound = this.audioTarget
        counter_sound.currentTime = 0
        counter_sound.play()
        const canVibrate = "vibrate" in navigator
        if (canVibrate) {
            navigator.vibrate(100)
        }
    }

    async createOrUpdate() {
        const headers = {
            'X-CSRF-Token': this.csrfToken,
            "Content-Type": "application/json"
        }
        const response = await fetch(this.createOrUpdateUrlValue, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(this.getParams())
        })
        const json = await response.json()
        this.existingIdValue = json.id
    }

    async createCounterHistory(type) {
        const headers = {
            'X-CSRF-Token': this.csrfToken,
            "Content-Type": "application/json"
        }
        await fetch(this.createCounterHistoryUrlValue, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                people_counter_history: {
                    date: this.dateTarget.value,
                    place_id: this.placeTarget.value,
                    for_exit: this.forExitValue,
                    counter_type: type
                }
            })
        })
    }

    getParams() {
        return {
            people_counter: {
                existing_counter_id: this.existingIdValue,
                date: this.dateTarget.value,
                place_id: this.placeTarget.value,
                for_exit: this.forExitValue,
                male_count: this.maleValue,
                female_count: this.femaleValue,
                neutral_count: this.neutralValue,
            }
        }
    }
}
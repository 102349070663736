// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'scroll-shadow-element'
import "chartkick/chart.js"


Rails.start()
ActiveStorage.start()

import LocalTime from "local-time"
import LocalTimeEs from './LocalTimeEs'
LocalTime.start()

import "controllers"

import "stylesheets/application"
import "stylesheets/custom"

import I18n from 'i18n-js'
import '../i18n/es'
I18n.defaultLocale = "es"
I18n.locale = "es"


/*
document.addEventListener('turbo:load', () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/pwa-sw.js');
    }
})
*/

import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/themes/dark.css")
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default class extends Flatpickr {

    static values = {
        specialEvents: Array,
        userWeekDay: Array,
        closedDays: Array,
        isPr: Boolean
    }

    connect() {
        this.config = {
            ...this.config,
            locale: Spanish,
            altInput: true,
            altFormat: "l j \\de F, Y - h:i K",
            time_24hr: false,
            disableMobile: false,
            enable: [function (date) {
                const isSpecialEvent = this.specialEventsValue.indexOf(date.toISOString().substring(0, 10)) !== -1
                if (isSpecialEvent) return true

                if (this.isPrValue) {
                    return this.userWeekDayValue.indexOf(date.getDay()) !== -1
                } else {
                    return this.closedDaysValue.indexOf(date.getDay()) === -1
                }

            }.bind(this)],
        };

        super.connect()
    }

    async disableDates(event) {
        const placeId = event.detail.place_id

        if (!placeId) {
            return
        }

        const dateRestrictions = await this.fetchDateRestrictions(placeId)

        this.closedDaysValue = dateRestrictions.closed_days
        this.specialEventsValue = dateRestrictions.special_events

        this.connect()
    }

    async fetchDateRestrictions(placeId) {
        const headers = {
            'X-CSRF-Token': this.csrfToken,
            "Content-Type": "application/json"
        }
        const response = await fetch(`/places/${placeId}/date_restrictions`, {
            headers: headers,
        })

        return await response.json()
    }

}
import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

    static targets = [ "feedback" ]

    static values = {
        url: String
    }

    async update(event) {
        const headers = {
            'X-CSRF-Token': this.csrfToken,
            "Content-Type": "application/json"
        }
        const data = {
            attended: event.target.checked
        }
        const response = await fetch(this.urlValue, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify(data)
        })
        this.feedbackTarget.innerHTML = await response.text()
    }
}

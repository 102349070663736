import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'checkbox' ]
    static values = {
    }

    checkAll() {
        this.checkboxTargets.forEach(it => {
            it.checked = true
        })
    }

    uncheckAll() {
        this.checkboxTargets.forEach(it => {
            it.checked = false
        })
    }
}

import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

    static targets = [ 'openHour', 'closeHour', 'maxTableSize' ]

    static values = {
        day: Number
    }

    disable() {
        this.openHourTarget.toggleAttribute('disabled')
        this.closeHourTarget.toggleAttribute('disabled')
        this.maxTableSizeTarget.toggleAttribute('disabled')
    }
}

import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

    static targets = [ 'date', 'attendantsList', 'submit' ]

    static values = {
        attendantsUrl: String,
        serverUrl: String,
        placeId: Number
    }

    updateForm(event) {
        if(!this.placeIdValue) {
            return
        }
        this.placeIdValue = event.target.value
    }

    placeIdValueChanged() {
        if(!this.placeIdValue) {
            return
        }
        this.getAttendants()
    }

    async getAttendants() {
        const url = new URL(this.serverUrlValue + this.attendantsUrlValue)
        const params = new URLSearchParams({
            place_id: this.placeIdValue,
            date: this.dateTarget.value
        })
        url.search = params.toString()
        const response = await fetch(url)
        this.attendantsListTarget.innerHTML = await response.text()
        this.submitTarget.removeAttribute('disabled')

    }
}

import { ApplicationController } from 'stimulus-use'

export default class extends ApplicationController {

    static values = {
        url: String
    }

    initialize() {
        this.saveBlob()
    }

    saveBlob() {
        let thisInstance = this
        fetch(this.urlValue)
            .then(response => response.blob())
            .then(function (blob) {
                thisInstance.blobby = blob
            })
    }

    share(event) {
        event.preventDefault();
        try {
            const files = [new File([this.blobby], "Reservación.png", { type: 'image/png' })]

            if (navigator.canShare) {

                if (navigator.canShare({ files })) {
                    navigator.share({ files: files })
                } else {
                    navigator.share({ url: this.urlValue })
                }

            } else {
                alert("Tu dispositivo no permite compartir. Toma una captura de pantalla.")
            }

        } catch (err) {
            console.log("Error compartiendo blob", err)
        }
    }
}
import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'
import QRCode from 'qrcode'

import dateFormat from "dateformat"
import { i18n } from "dateformat"
i18n.dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]
i18n.monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

export default class extends Controller {

    static targets = ['tablesSelector', 'date', 'persons', 'personRangeLabel', 'customer', 'canvas', 'encodedReservationImage', 'submitButton']

    static values = {
        placeId: Number,
        date: String,
        qrUrl: String,
        actionName: String,
        anonymousCustomerId: Number
    }

    connect() {
        useDispatch(this)
    }

    selectPersonsAmount() {
        this.personsTarget.select()
    }

    selectCustomerName() {
        this.customerTarget.select()
    }

    updateDate(event) {
        const dateValue = event.target.value
        if (dateValue) {
            this.dateValue = dateValue.substr(0, 10)
        }
    }

    dateValueChanged() {
        this.updateTables()
    }

    updatePlace(event) {
        if (!this.placeIdValue) {
            return
        }
        this.placeIdValue = event.target.value
        this.updateDatepicker()
    }

    placeIdValueChanged() {
        if (!this.placeIdValue) {
            return
        }
        this.updateTables()
    }

    updateDatepicker() {
        this.dispatch("placeChanged", { place_id: this.placeIdValue })
    }

    async updateTables() {
        const response = await fetch(`/places/${this.placeIdValue}/tables?date=${this.dateValue}`)
        this.tablesSelectorTarget.innerHTML = await response.text()
    }

    tableChanged(event) {
        this.userHasSelectedTable(event.target.selectedOptions[0])
    }

    userSelectedTableOnMap(event) {
        this.tablesSelectorTarget.getElementsByTagName('select')[0].value = event.detail.tableId
        this.userHasSelectedTable(this.tablesSelectorTarget.getElementsByTagName('select')[0].selectedOptions[0])
    }

    userHasSelectedTable(selectedOption) {
        if (selectedOption.value === '0') {
            this.personRangeLabelTarget.innerHTML = ''
            this.personsTarget.min = 1
            this.personsTarget.removeAttribute('max')
            return
        }
        const min = selectedOption.getAttribute('mincapacity')
        const max = selectedOption.getAttribute('maxcapacity')
        this.personsTarget.min = min
        // this.personsTarget.max = max
        this.personRangeLabelTarget.innerHTML = `(👨‍👦‍👦️ ${min}-${max} personas)`

        if (!this.customerTarget.value) {
            this.customerTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
            this.customerTarget.focus();
        } else if (!this.personsTarget.value) {
            this.personsTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
            this.personsTarget.focus();
        } 
    }

    setCustomer() {
        const customerForm = this.element.querySelector('form[action="/customers"]')
        if (!customerForm) {
            return
        }

        const customerNameInput = customerForm.querySelector('input#customer_name').cloneNode()
        customerNameInput.setAttribute('hidden', 'hidden')
        customerNameInput.setAttribute('name', 'reservation[customer_attributes][name]')
        this.element.appendChild(customerNameInput)

        const customerPhoneInput = customerForm.querySelector('input#customer_phone').cloneNode()
        customerPhoneInput.setAttribute('hidden', 'hidden')
        customerPhoneInput.setAttribute('name', 'reservation[customer_attributes][phone]')
        this.element.appendChild(customerPhoneInput)
    }

    // Reservation QR image code:

    reEnableSubmitButton() {
        this.submitButtonTarget.disabled = false
        this.submitButtonTarget.removeAttribute('disabled')
        this.submitButtonTarget.classList.remove('disabled:opacity-50', 'disabled:cursor-not-allowed')
    }

    generateReservationImage(event) {
        event.preventDefault()

        const customerName = this.hasCustomerTarget ? this.customerTarget.value : document.querySelector('#customer_name').value
        if (!customerName) {
            alert("Introduce el nombre del cliente")
            this.reEnableSubmitButton()
            return false
        }

        const dateFormatString = 'dddd d "de" mmmm "a las" h:MM tt' // i.e.: Martes 10 de Octubre a las 9:00 pm
        const date = new Date(`${this.dateTarget.value.replace(/-/g, '/')}:00`) // // Format needed for Safari iOS

        if (date < new Date() && this.actionNameValue !== 'edit' && document.querySelector('#reservation_customer_id').value != this.anonymousCustomerIdValue) {
            alert("La fecha de la reserva no puede ser anterior a la fecha y hora actuales");
            this.reEnableSubmitButton();
            return false;
        }

        const dateText = dateFormat(date, dateFormatString)

        const ctx = this.canvasTarget.getContext("2d");
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

        const background = document.getElementById("reservation_background");
        ctx.drawImage(background, 0, 0);

        const placeLogo = document.getElementById("place_logo");
        if (placeLogo) ctx.drawImage(placeLogo, 140, 280, 120, 120);

        ctx.fillStyle = "white";
        ctx.textAlign = "center";

        const fontSize = 15
        const textStart = 420
        const textSeparation = fontSize + 3

        ctx.font = `${fontSize}px Montserrat`;
        ctx.fillText(`Reservación para ${customerName}`, this.canvasTarget.width / 2, textStart);
        ctx.fillText(dateText, this.canvasTarget.width / 2, textStart + textSeparation);
        ctx.fillText(`${this.personsTarget.value} ${this.personsTarget.value == 1 ? 'persona' : 'personas'}`, this.canvasTarget.width / 2, textStart + textSeparation * 2);

        const qrOptions = {
            color: {
                dark: "#000000",
                light: "#FFFFFF",
                margin: 1
            },
            width: 260
        }

        const thisController = this

        const thisEventTarget = event.target

        QRCode.toCanvas(this.qrUrlValue, qrOptions, (error, qrCanvas) => {
            if (error) { alert(error) }
            ctx.drawImage(qrCanvas, 70, 10)
            thisController.encodedReservationImageTarget.value = thisController.canvasTarget.toDataURL()

            thisController.setCustomer()

            thisEventTarget.submit()
        })
    }

    testGenerateReservationImage(event) {
        event.preventDefault()
        this.generateReservationImage(event)
    }

    setAnonymous(event) {
        event.preventDefault();

        if (!this.tablesSelectorTarget.querySelector('select').selectedOptions[0].value) {
            alert("Selecciona una mesa para la reserva anónima");
            return false;
        }

        if (!confirm("¿Guardar reserva como anónima?")) {
            return false;
        }

        // If it is past 00:00 but before 5:00, and the date is empty, set the date to yesterday.
        if (new Date().getHours() < 5) {
            const yersterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10);
            this.dateTarget.value = yersterday
            document.querySelector('#reservation_date').value = yersterday
            document.querySelector('#reservation_date').nextElementSibling.value = yersterday
        }

        const selectedTableMaxCapacity = this.tablesSelectorTarget.querySelector('select').selectedOptions[0].getAttribute('maxcapacity');

        this.personsTarget.value = selectedTableMaxCapacity;

        this.customerTarget.value = 'Anónimo';
      
        document.querySelector('#reservation_customer_id').value = this.anonymousCustomerIdValue;

        this.submitButtonTarget.click();
    }

}
